<template>
  <v-container>
    <v-form @submit.prevent="save">
      <!-- Page Dropdown -->
      <v-select
        label="Select Page"
        v-model="selectedPage"
        :items="pages"
        outlined
        dense
        @change="loadPageSettings"
      />

      <!-- Page Title -->
      <v-text-field
        label="Page Title"
        v-model="meta.page_title"
        outlined
        dense
      />

      <!-- Meta Description -->
      <v-textarea
        label="Meta Description"
        v-model="meta.meta_description"
        outlined
        dense
      />

      <!-- Meta Keywords as Tags -->
      <v-combobox
        label="Meta Keywords"
        v-model="meta.meta_keywords"
        outlined
        dense
        chips
        clearable
        multiple
        :items="meta.meta_keywords"
        hint="Press Enter to add tags"
        persistent-hint
      />

      <!-- Meta Tags -->
      <v-textarea
        label="Meta Tags"
        v-model="meta.meta_tags"
        outlined
        dense
      />

      <!-- Meta Scripts -->
      <v-textarea
        label="Meta Scripts"
        v-model="meta.meta_script"
        outlined
        dense
      />

      <!-- Save Button -->
      <v-btn    v-if=" $hasPermission('manage business settings') "
      class="bg-primary text-white ml-2" @click="save">Save</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import { GENERAL_SETTINGS } from "@/core/services/store/config.module";

export default {
  name: "GeneralSetting",
  props: ["settings"], // Array of objects
  data() {
    return {
      pages: ["Home", "Menu", "Contactus", "Reservation"], // List of pages
      selectedPage: "Home", // Default selected page
      meta: {
        page_title: "",
        meta_description: "",
        meta_keywords: [],
        meta_tags: "",
        meta_script: "",
      },
    };
  },
  methods: {
    // Load settings for the selected page
    loadPageSettings() {
      const pageKeyPrefix = this.selectedPage.toLowerCase().replace(/\s+/g, "_");

      // Loop through settings to find and set meta fields
      this.meta.page_title = this.getSettingValue(`${pageKeyPrefix}_page_title`);
      this.meta.meta_description = this.getSettingValue(`${pageKeyPrefix}_meta_description`);
      this.meta.meta_keywords = this.getSettingValue(`${pageKeyPrefix}_meta_keywords`)
        ? this.getSettingValue(`${pageKeyPrefix}_meta_keywords`).split(",").map((keyword) => keyword.trim())
        : [];
      this.meta.meta_tags = this.getSettingValue(`${pageKeyPrefix}_meta_tags`);
      this.meta.meta_script = this.getSettingValue(`${pageKeyPrefix}_meta_script`);
    },

    // Helper method to fetch a value from settings by key
    getSettingValue(key) {
      const setting = this.settings.find((item) => item.key === key);
      return setting ? setting.value : ""; // Return value or empty string if not found
    },

    // Save the settings for the selected page
    save() {
  const pageKeyPrefix = this.selectedPage.toLowerCase().replace(/\s+/g, "_");

  // Prepare payload as an object, not an array
  const payload = {
    [`${pageKeyPrefix}_page_title`]: this.meta.page_title,
    [`${pageKeyPrefix}_meta_description`]: this.meta.meta_description,
    [`${pageKeyPrefix}_meta_keywords`]: this.meta.meta_keywords.join(","), // Join array into a string
    [`${pageKeyPrefix}_meta_tags`]: this.meta.meta_tags,
    [`${pageKeyPrefix}_meta_script`]: this.meta.meta_script,
  };

  // Dispatch to Vuex store
  this.$store
    .dispatch(GENERAL_SETTINGS, payload)
    .then((resp) => {
      if (resp.status === "Success") {
        Swal.fire("Information!", resp.message, "success");
      } else {
        Swal.fire("Error", resp.message, "error");
      }
    })
    .catch((error) => {
      Swal.fire("Error", error.message, "error");
    });
},


  },
  watch: {
    settings: {
      immediate: true,
      handler(newSettings) {
        if (newSettings) {
          this.loadPageSettings(); // Reload settings when `settings` changes
        }
      },
    },
    selectedPage() {
      this.loadPageSettings(); // Reload settings when the selected page changes
    },
  },
  mounted() {
    if (this.settings) {
      this.loadPageSettings(); // Load settings for the default page on mount
    }
  },
};
</script>
