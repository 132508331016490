<template>
  <!--begin::Card-->
  <div>
    <div class="form-group">
      <label>Business Description</label>
      <textarea
        v-model="businessDescriptionContent"
        rows="4"
        class="form-control"
      ></textarea>
    </div>
    <div class="form-group">
      <label>Privacy Policy</label>
      <quill-editor
        :content="privacyContent"
        :options="editorOption"
        @change="privacyEditorChanged($event)"
      />
    </div>

    <div class="form-group">
      <label>Terms and Conditions</label>
      <quill-editor
        :content="termsContent"
        :options="editorOption"
        @change="termsEditorChanged($event)"
      />
    </div>

    <div class="form-group">
      <label>Allergy Information</label>
      <quill-editor
        :content="allergyContent"
        :options="editorOption"
        @change="allergyEditorChanged($event)"
      />
    </div>

    <div class="form-group">
      <label>Contact Us</label>
      <quill-editor
        :content="contactContent"
        :options="editorOption"
        @change="contactEditorChanged($event)"
      />
    </div>

    <!-- Business Description as a textarea -->


    <hr />
    <div class="form-group" style="float: right">
      <b-button    v-if=" $hasPermission('manage business settings') "
      @click="save">Save</b-button>
    </div>
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { GENERAL_SETTINGS } from "@/core/services/store/config.module";

export default {
  name: "EmailSetting",
  props: ["settings"],
  components: {
    quillEditor,
  },
  data() {
    return {
      privacyContent: "",
      termsContent: "",
      allergyContent: "",
      contactContent: "",
      businessDescriptionContent: "", // Data property for textarea
      editorOption: {},
    };
  },
  methods: {
    privacyEditorChanged({ quill, html, text }) {
      this.privacyContent = html;
    },
    termsEditorChanged({ quill, html, text }) {
      this.termsContent = html;
    },
    allergyEditorChanged({ quill, html, text }) {
      this.allergyContent = html;
    },
    contactEditorChanged({ quill, html, text }) {
      this.contactContent = html;
    },
    save() {
      this.$store
        .dispatch(GENERAL_SETTINGS, {
          privacy: this.privacyContent,
          terms: this.termsContent,
          allergy: this.allergyContent,
          contact: this.contactContent,
          businessDescription: this.businessDescriptionContent // Include business description
        })
        .then(resp => {
          if (resp.status === "Success") {
            Swal.fire("Information!", resp.message, "success");
          } else {
            Swal.fire("Error", resp.message, "error");
          }
        })
        .catch(error => {
          Swal.fire("Error", error.message, "error");
          console.log(error);
        });
    },
    cancel() {
      //
    }
  },
  mounted() {
    for (let i = 0; i < this.settings.length; i++) {
      if (this.settings[i].key === "privacy") {
        this.privacyContent = this.settings[i].value;
      }
      if (this.settings[i].key === "terms") {
        this.termsContent = this.settings[i].value;
      }
      if (this.settings[i].key === "allergy") {
        this.allergyContent = this.settings[i].value;
      }
      if (this.settings[i].key === "contact") {
        this.contactContent = this.settings[i].value;
      }
      if (this.settings[i].key === "businessDescription") {
        this.businessDescriptionContent = this.settings[i].value; // Initialize business description
      }
    }
  }
};
</script>
